<template>
  <span class="order-status-name">
    <van-tag :type="type" plain >{{ name }}</van-tag>
  </span>
</template>

<script type="text/javascript">
export default {
  props: {
    order: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    type() {
      if (this.order.review_status === 3 || this.order.status === 99) {
        return 'danger'
      }
      if (this.order.status === 70) return 'success'
      return 'primary'
    },
    name() {
      if (this.order.status === 10) {
        return this.order.review_status_name
      }
      if (this.order.status === 30 || this.order.status === 60) {
        return this.order.logistics_status_name
      }
      return this.order.status_name;
      /*
      if (this.order.status === 4) return this.order.status_name
      if (this.order.review_status !== 2) {
        return this.order.review_status_name
      }
      if (this.order.pay_status !== 1) {
        return this.order.pay_status_name
      }
      if (this.order.logistics_status !== 1) {
        return this.order.logistics_status_name
      }
      return this.order.status_name
      */
    }
  },
}
</script>

<style scoped>
.order-status-name {
  flex: none;
}
</style>

<template>
  <div class="detail-container">
    <van-nav-bar
      :title="$t('order.detail')"
      :left-text="$t('back')"
      left-arrow
      @click-left="toOrder"
    />
    <div class="detail-content">
      <div class="detail-hd van-hairline--bottom">
        <svg-icon icon-class="item" />
        <div class="detail-hd-right">
          <div class="detail-hd-info">
            <span>{{$t('order.applyDate')}}：{{ order.created_time }}</span>
            <order-status :order="order" />
          </div>
          <div class="detail-hd-ft">{{ $t('embassyFee') }}: {{ +(order.dipm_fee || 0) }} SEK<span class="mlm">{{ $t('expressFee') }}: {{ order.delivery_fee || 0 }} SEK</span></div>
        </div>
      </div>
      <div class="detail-gap"></div>
      <div v-for="item in order.pn_order || []" :key="item.item_id">
        <div class="detail-list">
          <div class="detail-item">
            <p class="item-label" style="margin-bottom:0">{{item.type == 1 ? $t('form.pickUpInfo') : $t('form.deliveryInfo')}}：</p>
          </div>
        </div>
        <van-cell :title="$t('form.trackingNo')" :url="`https://tracking.postnord.com/se/?id=${item.item_id}`" is-link :value="item.item_id" />
        <div class="detail-gap"></div>
      </div>
      <div class="detail-list">
        <div class="detail-item" v-if="order.review_status === 3">
          <p style="color:black;font-weight:bold;">提示：请在七日内对申请单进行处理，超过七日未处理的申请单会自动删除。</p>
          <p class="item-label">{{$t('order.reject_reason')}}</p>
          <p class="item-value" style="color:red;font-size:20px;">{{ order.reject_reason }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.req_user_tip')}}</p>
          <p class="item-value">{{ order.req_user }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.conn_user')}}</p>
          <p class="item-value">{{ order.conn_user }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.conn_phone')}}</p>
          <p class="item-value">{{ order.conn_phone }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.conn_email')}}</p>
          <p class="item-value">{{ order.conn_email }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.company')}}</p>
          <p class="item-value">{{ order.company_name }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.to_address')}}</p>
          <p class="item-value">{{ order.to_address }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.city')}}</p>
          <p class="item-value">{{ order.city }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.postal_code')}}</p>
          <p class="item-value">{{ order.postal_code}}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.selectDipm')}}</p>
          <p class="item-value">{{ order.dipm_name }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.type')}}</p>
          <p v-for="item in order.orderCosts" :key="item.cost_id" class="item-value">{{ item.cost_name }}{{ order.is_urgent ? '（加急）' : '' }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.deliveryType')}}</p>
          <p class="item-value">{{deliveryTypeName}}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.attach')}}</p>
          <p class="item-value"></p>
          <uploader
            v-if="order.files && order.files.length"
            v-model="order.files"
            :show-upload="false"
            :deletable="false"
          />
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('remark')}}</p>
          <p class="item-value">{{ order.remark }}</p>
        </div>

      </div>
      <div class="apply-gap"></div>
    </div>
    <div class="detail-btn" v-if="order.status !== 99">
      <!-- <van-button round @click="toggleAddr" v-if="order.review_status === 2 && order.logistics_status > 0">修改地址</van-button> -->
      <van-button v-if="order.review_status === 3 || order.status < 20" round :to="`/apply?uuid=${order.uuid}`">{{$t('order.editApply')}}</van-button>
      <van-button round v-if="order.review_status === 1" @click="cancelOrder">{{$t('order.cancelApply')}}</van-button>
      <van-button round v-if="false && order.review_status === 2 && order.pay_status === 0" type="warning" disabled>支付申请单</van-button>
    </div>
    <!-- 待付快递费 -->
    <div class="detail-footer" v-if="order.status >= 20 && order.status < 70 && !order.delivery_pay_status">
      <van-submit-bar
        :price="(order.delivery_fee || 0) * 100"
        button-text="Confirm ⇀"
        :label="`${$t('order.total')}：`"
        :loading="paing"
        currency="SEK "
        button-type="warning"
        @submit="payDeliveryFee"
      >
        <div slot="tip">{{$t('order.delivery_fee_tip')}}</div>
      </van-submit-bar>
    </div>
    <!-- 待付使馆费 -->
    <div class="detail-footer" v-if="(order.dipm_id === 7 || order.status >= 50 && order.status < 70) && !order.dipm_pay_status && order.delivery_pay_status">
      <van-submit-bar
        :price="(+order.dipm_fee || 0) * 100"
        button-text="Confirm ⇀"
        :label="`${$t('order.total')}：`"
        currency="SEK "
        :loading="paing"
        button-type="warning"
        @submit="payDipmFee"
      >
        <div slot="tip">{{$t('order.dipm_fee_tip')}}</div>
      </van-submit-bar>
    </div>
    <van-dialog
      v-model="addrShow"
      title="修改派送地址"
      show-cancel-button
      :before-close="changeAddr"
    >
      <div class="detail-addr">
        <div class="detail-addr-tip">
          <svg-icon icon-class="warning" />
          <span>每修改一次地址收取50克朗</span>
        </div>
        <div class="detail-addr-ipt">
          <input placeholder="请输入新的派送地址" v-model="addr" />
        </div>
      </div>
    </van-dialog>

    <van-action-sheet
      v-model="showPayMethod"
      :actions="payWays"
      @select="payFinal"
      cancel-text="取消"
      close-on-click-action
    />

    <van-dialog v-model="swishShow" title="填写Swish账户" show-cancel-button :before-close="handleSwish">
      <Form
        validate-first
        ref="swishForm"
      >
        <van-field
          v-model="swishAccount"
          name="swishAccount"
          placeholder="请填写您的Swish账号"
          label="手机号码"
          :rules="[{pattern: /^467\d{8}$/, message: '正确格式：46712345678'}]"
        />
      </Form>
    </van-dialog>
  </div>
</template>

<script type="text/javascript">
import { SubmitBar } from 'vant'
import OrderStatus from '@/components/OrderStatus'
import order from '@/api/order'
import { Uploader, Form } from 'vant'
export default {
  components: { OrderStatus, VanSubmitBar: SubmitBar, Uploader, Form },
  data() {
    return {
      swishShow: false,
      addrShow: false,
      addr: '',
      order: {},
      paing: false,
      showPayMethod: false,
      swishAccount: '',
      pay_id: 0,
      payMethods: [
        { name: 'Swish' },
        //{ name: 'Klarna' },
        //{ name: '微信', pay_type: 1 },
        //{ name: '支付宝', pay_type: 2 },
      ]
    }
  },
  computed: {
    payWays() {
      // 使馆费
      if (this.pay_id == 1) {
        return this.payMethods.filter(v => v.name === 'Swish')
      }
      return this.payMethods
    },
    deliveryTypeName() {
      if (this.order.delivery_type == 10) {
        return '双程'
      }
      else if (this.order.delivery_type == 20) {
        return '单程派送'
      }
      else if (this.order.delivery_type == 30) {
        return '单程补件'
      }
      return ''
    },
  },
  created() {
    this.getDetail()
    if (this.$route.query.klarna_order_id) {
      this.$dialog.alert({
        title: '支付提示',
        message: '我们正在处理您的支付，请您耐心等待！',
        confirmButtonText: '我知道了'
      })
    }
  },
  methods: {
    getDetail() {
      const { uuid } = this.$route.params
      const cb = this.$toast.loading('加载中...')
      order.detail(uuid)
      .then(res => {
        res.files = (res.files || []).map(v => {
          return {
            url: `/rest/order/file/${v.id}/download`,
            isImage: true
          }
        })
        res.pn_order = (res.pn_order || []).sort((a, b) => a.type - b.type)
        console.log(res);
        this.order = res
        cb.clear();
      })
      .catch(() => {
        cb.clear()
      })
    },
    toOrder() {
      this.$router.push(`/`)
    },
    toggleAddr() {
      this.addr = this.order.to_address
      this.addrShow = true
    },
    changeAddr(action, done) {
      if (action === 'cancel') {
        done()
        return false
      }
      if (!this.addr) {
        this.$toast('请填写派送地址')
        done(false)
        return false
      }
      order.update(this.order.uuid, { to_address: this.addr })
      .then(() => {
        this.order.to_address = this.addr
        this.$toast('修改成功')
        done()
      })
      .catch(() => {
        done(false)
      })
    },
    cancelOrder() {
      this.$dialog.confirm({
        title: '提示',
        message: '您确定要取消该申请单吗？',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            order.update(this.order.uuid, { status: 99 })
            .then(() => {
              this.getDetail()
              this.$toast('取消成功')
              done()
            })
            .catch(done)
          }
          else {
            done()
          }
        }
      })
    },
    payDeliveryFee() {
      this.showPayMethod = true
      this.pay_id = 2
    },
    payDipmFee() {
      this.showPayMethod = true
      this.pay_id = 1
    },
    payFinal(item) {
      if (item.name === 'Swish') {
        this.swishAccount = ''
        this.swishShow = true
        return;
      }
      if (item.name === 'Klarna') {
        this.handleKlarna()
        return;
      }
      this.pay(this.pay_id, item.pay_type)
    },
    handleKlarna() {
      this.$router.push(`/pay-klarna/${this.order.uuid}/${this.pay_id}`)
    },
    pay(type, method) {
      this.paing = true
      order.pay(this.order.uuid, type, method)
      .then((res) => {
        this.paing = false
        if (res.code === '200') {
          window.location.href = res.data.url
        }
        else {
          this.$toast(res.message || '支付失败')
        }
        /*
        const { operations } = res
        window.location.href = operations.find(v => v.rel === 'redirect-authorization').href
        */
      })
      .catch(() => {
        this.paing = false
      })
    },
    handleSwish(action, done) {
      if (action === 'confirm') {
        this.$refs.swishForm.validate()
        .then(() => {
          order.swishPay(this.order.uuid, {pay_type: this.pay_id, phone: this.swishAccount})
          .then(() => {
            done()
            this.$dialog.alert({
              title: '支付提示',
              message: '账单已发送到您的Swish账户，支付成功后刷新当前页面即可查看支付状态。',
              confirmButtonText: '我已支付'
            })
            .then(() => {
              this.getDetail()
            })
          })
          .catch(() => {
            done(false)
          })
        })
        .catch(() => {
          done(false)
        })
      }
      else {
        done()
      }
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    padding-bottom: 76px;
  }
  &-content {
    flex: 1;
    overflow-y: auto;
  }
  &-hd {
    display: flex;
    padding: 18px 15px 10px;
    .svg-icon {
      font-size: 22px;
    }
    &-right {
      flex: 1;
      margin-left: 15px;
    }
    &-info {
      display: flex;
      justify-content: space-between;
    }
    &-ft {
      color: #666;
      margin-top: 10px;
    }
  }
  &-list {
    padding: 15px 15px 0;
  }
  &-item {
    font-size: 14px;
    line-height: 20px;
    p {
      margin: 0;
    }
    .item-label {
      color: #999;
      margin-bottom: 8px;
    }
    .item-value {
      color: #333;
      margin-bottom: 16px;
    }
  }
  &-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 15px;
    text-align: right;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    .van-button {
      margin-left: 15px;
    }
  }
  &-addr {
    padding: 15px 0;
    &-tip {
      height: 40px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #D94C50;
      background-color: #FFF1F2;
      .svg-icon {
        margin-right: 6px;
      }
    }
    &-ipt {
      margin-top: 15px;
      padding: 0 15px;
      input {
        border: 0 none;
        outline: none;
        border-bottom: 1px solid #ddd;
        width: 100%;
        padding: 10px 0;
        &:focus {
          border-bottom-color: @orange;
        }
      }
    }
  }
  &-gap {
    background: #F0F4F6;
    height: 8px;
  }
}
.swish-input {
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  input {
    width: 100%;
    outline: none;
  }
}
</style>
